 

@font-face {
  font-family: 'Futura Std';
  src: url('./assets/fonts/FuturaStd-Light.woff2') format('woff2'),
      url('./assets/fonts/FuturaStd-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('./assets/fonts/FuturaStd-Book.woff2') format('woff2'),
      url('./assets/fonts/FuturaStd-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('./assets/fonts/FuturaStd-Heavy.woff2') format('woff2'),
      url('./assets/fonts/FuturaStd-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}








.App {
  background-color:var(--background);
  height: 100vh;
  padding: 30px 30px;
}




@media (max-width: 800px) {
  .App {
    padding: 0;
  }
}

.ui-header-page{   
  width: 710px;
  margin: 0 auto;
  max-width: 100%;}
.ui-header-page__title{
  font-size: 3em;
   text-align: center;
  font-weight: 700;
  letter-spacing: 0px;
  margin-bottom: 30px;
  margin-top: 70px;
}
.ui-header-page__subtitle{
  font-size: 1.6em;
   text-align: center;
  font-weight: 300;
  /* margin-top: 0; */
  line-height: 1.3;
  /* margin-bottom: 20px; */
}