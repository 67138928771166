.cards{
    background:red;
    border-radius: 10px;
    overflow: hidden;
}

.ui-cards__container{
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    background-color: var(--background);
    color: var(--on-background);
}
