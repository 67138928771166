:root {
  /* --background: #dedede;
  --surface: #ffffff;
  --error: #b00020;
  --primary: #6200ee;
  --primary-variant: #3700b3;
  --secundary: #005fb3;
  --secundary-variant: #018786;
  --on-background: #000000;
  --on-surface: #000000;
  --on-primary: #ffffff;
  --on-secundary: #000000;
  --on-error: #ffffff;
  --background-hero: url(../src/components/hero/hero-light.svg); */
  --background: #000;
  --surface: #080808;
  --error: #cf6679;
  --primary: #bb86fc;
  --primary-variant: #3700b3;
  --secundary: #f49603;
  --secundary-variant: #03dac6;
  --on-background: #ffffff;
  --on-surface: #ffffff;
  --on-primary: #000000;
  --on-secundary: #000000;
  --on-error: #000000;
  --background-hero: url(/static/media/hero-dark.12e791cc.jpg);
}
@media (prefers-color-scheme: dark) {
  :root {
    --background: #000;
    --surface: #080808;
    --error: #cf6679;
    --primary: #bb86fc;
    --primary-variant: #3700b3;
    --secundary: #f49603;
    --secundary-variant: #03dac6;
    --on-background: #ffffff;
    --on-surface: #ffffff;
    --on-primary: #000000;
    --on-secundary: #000000;
    --on-error: #000000;
    --background-hero: url(/static/media/hero-dark.12e791cc.jpg);
  }
}

html {
  scroll-behavior: smooth;
}

.skip-link {
  background: #ffffff;
  background: var(--on-background);
  color: #000;
  color: var(--background);
  z-index: 999;
  /* height: 75px; */
  left: 50%;
  padding: 8px;
  position: absolute;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  margin-left: -112px;
}
.skip-link:hover {
  color: #000;
  color: var(--background);
}
.skip-link:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Futura Std", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sr-only {
  position: absolute !important;
  left: -10000px !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
}

 

@font-face {
  font-family: 'Futura Std';
  src: url(/static/media/FuturaStd-Light.cd593ad7.woff2) format('woff2'),
      url(/static/media/FuturaStd-Light.f5fe0757.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url(/static/media/FuturaStd-Book.0129945e.woff2) format('woff2'),
      url(/static/media/FuturaStd-Book.34dd0daf.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url(/static/media/FuturaStd-Heavy.57de2b20.woff2) format('woff2'),
      url(/static/media/FuturaStd-Heavy.f1573a3a.woff) format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}








.App {
  background-color:var(--background);
  height: 100vh;
  padding: 30px 30px;
}




@media (max-width: 800px) {
  .App {
    padding: 0;
  }
}

.ui-header-page{   
  width: 710px;
  margin: 0 auto;
  max-width: 100%;}
.ui-header-page__title{
  font-size: 3em;
   text-align: center;
  font-weight: 700;
  letter-spacing: 0px;
  margin-bottom: 30px;
  margin-top: 70px;
}
.ui-header-page__subtitle{
  font-size: 1.6em;
   text-align: center;
  font-weight: 300;
  /* margin-top: 0; */
  line-height: 1.3;
  /* margin-bottom: 20px; */
}
header{
    position: fixed;
    width: 100%;
   padding: 15px
}

header ul {
    margin: 20px 0 0 0;
    padding: 0;
    list-style: none;
}

 
header ul li {
    margin-bottom: 10px;
}
header ul li a {
    text-decoration: none;
    color: #000;
}





.ui-hero{
     height: calc(100vh - 60px);
    margin-bottom: 30px;
    border-radius: 10px;
    display: flex;
    background-image: var(--background-hero);

    background-blend-mode: luminosity;
    /* background: url(/static/media/image.12e791cc.jpg), -webkit-linear-gradient(to left, #2C5364, #203A43, #0F2027);  Chrome 10-25, Safari 5.1-6 */
    background-color:var(--surface); 
     background-size: contain;
    background-repeat: no-repeat;

    background-position: right center;

    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

}


 
.ui-hero__box{
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 600px;
}


.ui-hero__container{
    max-width: 100%;
    width: 960px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}
.ui-hero__title {
    color: var(--on-surface);
    font-size: 4rem;
    margin: 0 0 20px 10px;
    padding: 0;
    letter-spacing: -0.1rem;
    font-weight: 600;
}

.ui-hero__title-name{
    display: block;
}

.ui-hero__subtitle  {
    color: var(--on-surface);
    margin: 0 0 0 15px;
    font-size: 1.6em;
    padding: 0;
    opacity: 0.8;
}
    
.ui-hero__subtitle-break{ 
    display: block;
    margin: 4px 0;
}

.ui-hero__subtitle span{
    color: var(--secundary);
    
}

@media (max-width: 800px) {
    .ui-hero{ 
        background-size: cover;
        background-position: left center;
        border-radius: 0;
    }
    .ui-hero__title { 
        font-size: 4rem;
        letter-spacing: -0.2rem;    
    }
    
    .ui-hero__subtitle  {
        font-size: 1.8rem;
    }

    .ui-hero__box { 
        align-self: flex-end;
        margin-bottom: 30px;
    }

    
}
.cards{
    background:red;
    border-radius: 10px;
    overflow: hidden;
}

.ui-cards__container{
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: var(--background);
    color: var(--on-background);
}

.works {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0;
}
.work {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.2s linear;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  width: 40%;
  padding: 0;
  background-color: var(--surface);
}

.work.active {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--surface);
  z-index: 1000;
  margin: 0;
  -webkit-transform: translateY(0px) !important;
          transform: translateY(0px) !important;
}

@media (max-width: 768px) {
  .work {
    width: 100%;
  }
}

.work:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}

.work__body {
  transition: all 0.2s linear;
  position: absolute;
  width: 80%;
  bottom: 5px;
  left: 0;
  right: 0;
  width: -webkit-max-content;
  width: max-content;
}

.work__title {
  font-family: "Futura Std", Arial, Helvetica, sans-serif;
  font-size: 1.7em;
  margin: 0;
  font-weight: 600;
  color: var(--on-surface);
  background-color: var(--surface);
  padding: 5px 15px;
  transition: all 0.2s linear;
  border-radius: 0 20px 20px 0;
}
.work__link {
  text-decoration: none;
  color: var(--on-surface);
}
.work__image {
  display: flex;
  width: 100%;
}

.cards{
    background: #fff;
    border-radius: 10px;
}

.ui-cards__container{
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}


.contactForm{
    padding: 20px;
    width: 400px;
    margin: 0 auto;
}

.contactForm input{
    display: block;
    width: 100%;
    margin-bottom: 10px;
    height: 37px;
    text-indent: 1rem;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid #333333;
}

.contactForm textarea{
    display: block;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #333333;
    text-indent: 1rem;
    font-size: 14px;
    border-radius: 10px;
}

.contactForm button {
    border: 1px solid #000;
    background: #333333;
    color: #fff;
    font-size: 1rem;
    height: 37px;
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 10px;
    font-weight: bold;
}
