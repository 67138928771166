.works {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0;
}
.work {
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin: 10px;
  padding: 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.2s linear;
  position: relative;
  overflow: hidden;
  transform: translateY(0px);
  width: 40%;
  padding: 0;
  background-color: var(--surface);
}

.work.active {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--surface);
  z-index: 1000;
  margin: 0;
  transform: translateY(0px) !important;
}

@media (max-width: 768px) {
  .work {
    width: 100%;
  }
}

.work:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  transform: translateY(-7px);
}

.work__body {
  transition: all 0.2s linear;
  position: absolute;
  width: 80%;
  bottom: 5px;
  left: 0;
  right: 0;
  width: max-content;
}

.work__title {
  font-family: "Futura Std", Arial, Helvetica, sans-serif;
  font-size: 1.7em;
  margin: 0;
  font-weight: 600;
  color: var(--on-surface);
  background-color: var(--surface);
  padding: 5px 15px;
  transition: all 0.2s linear;
  border-radius: 0 20px 20px 0;
}
.work__link {
  text-decoration: none;
  color: var(--on-surface);
}
.work__image {
  display: flex;
  width: 100%;
}
