header{
    position: fixed;
    width: 100%;
   padding: 15px
}

header ul {
    margin: 20px 0 0 0;
    padding: 0;
    list-style: none;
}

 
header ul li {
    margin-bottom: 10px;
}
header ul li a {
    text-decoration: none;
    color: #000;
}




