.ui-hero{
     height: calc(100vh - 60px);
    margin-bottom: 30px;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    background-image: var(--background-hero);

    background-blend-mode: luminosity;
    /* background: url(/static/media/image.12e791cc.jpg), -webkit-linear-gradient(to left, #2C5364, #203A43, #0F2027);  Chrome 10-25, Safari 5.1-6 */
    background-color:var(--surface); 
     background-size: contain;
    background-repeat: no-repeat;

    background-position: right center;

    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

}


 
.ui-hero__box{
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 600px;
}


.ui-hero__container{
    max-width: 100%;
    width: 960px;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
}
.ui-hero__title {
    color: var(--on-surface);
    font-size: 4rem;
    margin: 0 0 20px 10px;
    padding: 0;
    letter-spacing: -0.1rem;
    font-weight: 600;
}

.ui-hero__title-name{
    display: block;
}

.ui-hero__subtitle  {
    color: var(--on-surface);
    margin: 0 0 0 15px;
    font-size: 1.6em;
    padding: 0;
    opacity: 0.8;
}
    
.ui-hero__subtitle-break{ 
    display: block;
    margin: 4px 0;
}

.ui-hero__subtitle span{
    color: var(--secundary);
    
}

@media (max-width: 800px) {
    .ui-hero{ 
        background-size: cover;
        background-position: left center;
        border-radius: 0;
    }
    .ui-hero__title { 
        font-size: 4rem;
        letter-spacing: -0.2rem;    
    }
    
    .ui-hero__subtitle  {
        font-size: 1.8rem;
    }

    .ui-hero__box { 
        align-self: flex-end;
        margin-bottom: 30px;
    }

    
}